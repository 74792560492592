import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery, navigate } from "gatsby";
import { useTranslation } from "react-i18next";

import {
  PageWrapper,
  Title,
  OffersWrapper,
  OfferWrapper,
  OfferTitle,
  OfferSubtitle,
  Info,
  InfoText,
  DescriptionsWrapper,
  Description,
  WhatDoWeOfferWrapper,
  WhatDoWeOfferTitle,
  Link,
  ArrowWrapper,
} from "./style";
import { OfferType } from "../utils";
import { PageChange } from "../../../../../common/Scroll";
import { replaceWithJSX } from "../../../../../../utils/common";
import { fixConjunctions } from "../../../../../../utils/dropTheWord";
import { getLanguage } from "../../../../../../utils/i18n";
import ArrowBottom from "-!svg-react-loader!../../../../../../assets/images/arrowDown.svg";
import { PageTitle } from "../../../../../common/PageTitle";

interface CurrentOffersProps {
  pageChange: PageChange;
  setPageChange: (value: PageChange) => void;
}

const CurrentOffersMobile: React.FC<CurrentOffersProps> = ({
  pageChange,
  setPageChange,
}) => {
  const [textWidth, setTextWidth] = useState<number[]>([]);

  useEffect(() => {
    const handleWindowResize = () => {
      const textElementsWidth = Array.from(
        document.getElementsByClassName("info")
      ).map(text => text.clientWidth);
      setTextWidth(textElementsWidth);
    };

    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const mainLanguage = getLanguage() === "en" ? "en" : "pl";
  const secondLanguage = mainLanguage === "en" ? "pl" : "en";
  const { t } = useTranslation("career");
  const data = useStaticQuery(offersQuery);
  const offers: OfferType[] = data[mainLanguage].nodes;
  const offersAlternative: OfferType[] = data[secondLanguage].nodes;

  const goToOffer = (id: number) => {
    setPageChange("next");
    setTimeout(() => {
      navigate(`/offer/${id}`);
    }, 600);
  };

  const goToRODO = () => {
    setPageChange("next");
    setTimeout(() => {
      navigate("/rodo");
    }, 600);
  };

  return (
    <>
      <PageTitle subtitle={t(`currentOffers_title`)} />
      <PageWrapper animation={pageChange}>
        {offers && offers.length > 0 ? (
          <>
            <Title>{t(`currentOffers_title`)}</Title>
            <OffersWrapper>
              {offers.map(({ title, subtitle, originalId }, index: number) => (
                <OfferWrapper key={index} onClick={() => goToOffer(originalId)}>
                  <OfferTitle>
                    {fixConjunctions(title || offersAlternative[index].title)}
                  </OfferTitle>
                  {subtitle ? (
                    <OfferSubtitle>{fixConjunctions(subtitle)}</OfferSubtitle>
                  ) : (
                    offersAlternative[index].subtitle && (
                      <OfferSubtitle>
                        {fixConjunctions(offersAlternative[index].subtitle!)}
                      </OfferSubtitle>
                    )
                  )}
                </OfferWrapper>
              ))}
            </OffersWrapper>
          </>
        ) : (
          <div>
            <Title>{fixConjunctions(t(`currentOffers_noOffersTitle`))}</Title>
            <DescriptionsWrapper>
              <Description
                dangerouslySetInnerHTML={{
                  __html: fixConjunctions(
                    t(`currentOffers_noOffersDescription`).replace("\n", "\n\n")
                  ),
                }}
              />
            </DescriptionsWrapper>
          </div>
        )}
        <Info>
          {t(`currentOffers_information`)
            .split("\n")
            .map((text, index) => (
              <InfoText
                key={index}
                className="info"
                dangerouslySetInnerHTML={{ __html: text }}
                index={index}
                pageWidth={window.innerWidth}
                width={textWidth[index] || 256}
              />
            ))}
        </Info>
        <DescriptionsWrapper>
          <Description
            dangerouslySetInnerHTML={{
              __html: fixConjunctions(t(`currentOffers_firstParagraph`)),
            }}
          />
          <Description
            dangerouslySetInnerHTML={{
              __html: fixConjunctions(t(`currentOffers_secondParagraph`)),
            }}
          />
          <Description>
            {replaceWithJSX(
              fixConjunctions(t(`currentOffers_thirdParagraph`)),
              /{:rodo:}/,
              <Link onClick={goToRODO} key="rodoLink">
                {t(`currentOffers_rodo`)}
              </Link>
            )}
          </Description>
        </DescriptionsWrapper>
      </PageWrapper>
    </>
  );
};

const offersQuery = graphql`
  {
    en: allDatoCmsOffer(filter: { locale: { eq: "en" } }) {
      nodes {
        title
        subtitle
        originalId
      }
    }
    pl: allDatoCmsOffer(filter: { locale: { eq: "pl" } }) {
      nodes {
        title
        subtitle
        originalId
      }
    }
  }
`;

export default CurrentOffersMobile;

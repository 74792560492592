import styled, { css } from "styled-components";

import {
  InfoProps,
  moveLeftRightLoop,
  moveRightLeftLoop,
} from "../Desktop/style";
import { device } from "../../../../../../utils/breakpoints";
import { fadeIn, fadeOut } from "../../../../../../utils/animations";
import {
  H4,
  H5,
  SUBTITLE_1,
  TEXT_BODY_2,
  TEXT_BUTTON_1,
  TEXT_BUTTON_2,
} from "../../../../../../assets/styles/typography";

interface PageProps {
  animation?: string;
}

export const PageWrapper = styled.div`
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms linear
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;
`;

export const Title = styled.h1`
  ${H4}
  width: 100%;
  margin: 0;
  padding: 112px 24px 32px 24px;
  color: #013cff;
  white-space: pre-line;
`;

export const OffersWrapper = styled.div`
  width: 100%;
  margin: 0;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const OfferWrapper = styled.div`
  width: 100%;
  height: auto;
  margin: 12px 0;
  color: #151515;

  &:hover {
    cursor: pointer;
    color: #013cff;
  }

  @media ${device.mobileS} {
    margin: 24px 0;
  } ;
`;

export const OfferTitle = styled.h2`
  ${SUBTITLE_1}
  margin: 0;
  color: inherit;
  white-space: pre-line;
`;

export const OfferSubtitle = styled.h3`
  margin: 0;
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  color: inherit;
  white-space: pre-line;
`;

export const Info = styled.div`
  position: relative;
  min-height: 90px;
  margin: 24px 48px;
`;

export const InfoText = styled.div`
  ${TEXT_BODY_2}
  position: absolute;
  top: ${(props: InfoProps) => `${props.index * 29}px`};
  color: #013cff;
  white-space: pre;
  animation: ${(props: InfoProps) =>
      props.width < props.pageWidth
        ? moveRightLeftLoop(props.width)
        : moveLeftRightLoop(props.width)}
    30s linear infinite;
  overflow: hidden;

  &:nth-of-type(2) {
    animation: ${(props: InfoProps) =>
        props.width < props.pageWidth
          ? moveLeftRightLoop(props.width)
          : moveRightLeftLoop(props.width)}
      30s linear infinite;
  }
`;

export const DescriptionsWrapper = styled.div`
  flex: 3;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

export const Description = styled.div`
  ${TEXT_BODY_2}
  width: 100%;
  color: #151515;
  white-space: pre-line;

  &:not(:last-of-type) {
    margin: 0 0 16px;
  }
`;

export const Link = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: #013cff;
  font-weight: 700;
  white-space: pre-line;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const ArrowWrapper = styled.div`
  align-self: flex-end;

  svg > * {
    fill: #ffffff;
  }
`;
